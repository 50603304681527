import React, { FC, useEffect } from 'react';

import styled from 'styled-components';

const FormStyling = styled.div<{ gradient: boolean; formId: number; mobile: boolean }>`
  width: 100%;
  form {
    ._form-content {
      ._button-wrapper {
        ${({ gradient, formId, theme, mobile }) => `button#_form_${formId}_submit{
          position: relative !important;
          background: ${
            gradient ? '#FFF !important' : `${theme.config.gradients.default} !important`
          };
          color: ${gradient ? `${theme.palette.grey['800']} !important` : `#FFF !important`};
          border-radius: 2rem !important;
          border: none !important;
          padding: 1rem 1rem 1rem 2rem !important;
          font-weight: 700 !important;
          font-size: 1.4rem !important;
          line-height: 1.4rem !important;
          white-space: nowrap !important;
          text-transform: uppercase !important;
          font-family: ${theme.config.fonts.PTSans} !important;
          display: flex;
          align-items: center;
          margin-top: 1rem !important;
          margin-left: ${mobile ? `auto` : 0};
          margin-right: ${mobile ? `auto` : 0};
          &:after{
            content: '';
            display: block;
            background: ${gradient ? 'url(/button_arrow_dark.svg)' : 'url(/button_arrow.svg)'};
            width: 1.5rem;
            background-size: 1.5rem;
            height: 1.5rem;
            margin-left: 1.5rem;
          }
          }`}
      }
    }
  }
`;

type ActiveCampaignFormProps = {
  formId: number;
  isMobile: boolean;
  gradient?: boolean;
};

export const ActiveCampaignForm: FC<ActiveCampaignFormProps> = ({
  formId,
  isMobile,
  gradient = false,
}) => {
  useEffect(() => {
    const script = document.createElement('script');

    script.src = `https://homeproved.activehosted.com/f/embed.php?id=${formId}`;
    script.async = true;

    document.body.appendChild(script);

    return () => {
      script.remove();
      document
        .querySelector(`form[action="https://homeproved.activehosted.com/proc.php"]`)
        ?.parentElement?.remove();
    };
  }, [formId]);

  return (
    <FormStyling gradient={gradient} formId={formId} mobile={isMobile}>
      <div className={`_form_${formId}`} />
    </FormStyling>
  );
};
