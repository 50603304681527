import { SectorsApiFactory, useApiFactory, useQueryFetch } from '@homeproved/shared/data-access';
import { useCurrentLanguage } from '@homeproved/shared/feature-i18n';
import QUERY_KEYS from './queryKeys';

export const useArticleTeasers = () => {
  const currentLanguage = useCurrentLanguage();

  const articleTeasersApi = useApiFactory(SectorsApiFactory);
  const { query } = useQueryFetch([QUERY_KEYS.ARTICLE_TEASERS, currentLanguage], () =>
    articleTeasersApi.apiSectorTeasersGet()
  );
  return {
    data: query.data,
    isSuccess: query.isSuccess,
    isError: query.isError,
    isLoading: query.isLoading,
  };
};
