import React, { FC } from 'react';
import styled from 'styled-components';
import { ActiveCampaignForm } from '@homeproved/shared/feature-active-campaign';
import { useCurrentLanguage } from '@homeproved/shared/feature-i18n';

type NewsletterSubscriptionTileProps = {
  isMobile?: boolean;
  isTablet?: boolean;
};

const Wrapper = styled.div`
  position: relative;
  padding: 10rem 2rem 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: ${({ theme }) => theme.config.gradients.rotated};
  border-radius: ${({ theme }) => theme.config.defaultBorderRadius};
`;

const FormCSSWrapper = styled(({ mobile, ...restProps }) => <div {...restProps} />)`
  form {
    padding: 0 !important;
    margin: 0 !important;
    width: auto !important;
    ._form_element {
      margin: 0 !important;
      ._html-code {
        p.legal {
          margin-top: 0.5rem !important;
        }
        p.legal,
        a {
          font-size: 1.2rem !important;
          text-align: left !important;
          line-height: 1.6rem !important;
          font-family: ${({ theme }) => theme.config.fonts.PTSans} !important;
          color: #fff !important;
        }
      }
    }
    ._form-content {
      ._form-title {
        display: block;
        font-family: ${({ theme }) => theme.config.fonts.Cabrito} !important;
        font-size: 2rem !important;
        font-weight: 600 !important;
        text-align: center !important;
        color: #fff !important;
      }
      p {
        display: block;
        font-family: ${({ theme }) => theme.config.fonts.PTSans} !important;
        font-size: 1.3rem !important;
        font-weight: 300 !important;
        text-align: center !important;
        color: #fff !important;
        margin: 0 0 1rem !important;
      }
      label {
        font-size: 1.4rem !important;
        font-weight: 800 !important;
        margin-bottom: 0 !important;
        font-family: ${({ theme }) => theme.config.fonts.PTSans} !important;
        color: ${({ theme }) => theme.palette.text.primary} !important;
      }
      ._form-label {
        display: none !important;
      }
      ._field-wrapper {
        border: none !important;
        border-radius: ${({ theme }) => theme.config.defaultBorderRadius} !important;
        margin-bottom: 1rem;
        padding: 0;
        input,
        textarea {
          padding: 1rem !important;
          height: 4rem !important;
          background: #f4d7d1 !important;
          border: 0.1rem solid #f4d7d1 !important;
          border-radius: ${({ theme }) => theme.config.defaultBorderRadius} !important;
          font-family: ${({ theme }) => theme.config.fonts.Cabrito} !important;
          font-size: 1.6rem !important;
          color: ${({ theme }) => theme.palette.text.primary} !important;
          outline: none !important;
          &:hover {
            border: 0.1rem solid #333 !important;
          }
        }
      }
      ._button-wrapper {
        ${({ gradient, formId, theme, mobile }) => `button#_form_${formId}_submit{
          position: relative !important;
          background: ${
            gradient ? '#FFF !important' : `${theme.config.gradients.default} !important`
          };
          color: ${gradient ? `${theme.palette.grey['800']} !important` : `#FFF !important`};
          border-radius: 2rem !important;
          border: none !important;
          padding: 1rem 1rem 1rem 2rem !important;
          font-weight: 700 !important;
          font-size: 1.4rem !important;
          line-height: 1.4rem !important;
          white-space: nowrap !important;
          text-transform: uppercase !important;
          font-family: ${theme.config.fonts.PTSans} !important;
          display: flex;
          align-items: center;
          margin-top: 1rem !important;
          margin-left: ${mobile ? `auto` : 0};
          margin-right: ${mobile ? `auto` : 0};
          &:after{
            content: '';
            display: block;
            background: ${gradient ? 'url(/button_arrow_dark.svg)' : 'url(/button_arrow.svg)'};
            width: 1.5rem;
            background-size: 1.5rem;
            height: 1.5rem;
            margin-left: 1.5rem;
          }
          }`}
      }
      ._checkbox-radio {
        display: flex !important;
        input[type='checkbox'] {
          flex-shrink: 0;
        position: relative;
        }
        span {
          padding-left: 1rem !important;
        }

        label {
          display: inline-block !important;
          font-size: 1.2rem !important;
          font-weight: 300 !important;
          line-height: 1.6rem !important;
          color: #fff !important;
        }
        ._error-inner {
          top: 100%;
          left: 0;
          position: absolute;
          z-index: 99;
        }
      }
    }
    ._button-wrapper {
      display: block !important;
      text-align: center !important;

      button {
        display: inline-flex !important;
      }
    }
    ._form-thank-you {
      color: #fff !important;
      margin-top: 3rem !important;
      font-family: ${({ theme }) => theme.config.fonts.Cabrito} !important;
      font-size: 2rem;
      text-align: left !important;
      white-space: pre-line !important;
    }
  }
`;

const PaperPlane = styled(({ tablet, alt, ...restProps }) => <img alt={alt} {...restProps} />)`
  position: absolute;
  top: 1rem;
  left: 2rem;
  width: ${({ tablet }) => (tablet ? '52%' : '69%')};
`;

export const NewsletterSubscriptionTile: FC<NewsletterSubscriptionTileProps> = ({
  isTablet,
  isMobile,
}) => {
  const currentLanguage = useCurrentLanguage();

  const getFormId = () => {
    switch (currentLanguage) {
      case 'nl':
        return 5;
      case 'fr':
        return 13;
      case 'en':
        return 9;
      default:
        return 5;
    }
  };

  return (
    <Wrapper>
      <PaperPlane src="/vlieger.svg" alt="" tablet={isTablet} />
      <FormCSSWrapper mobile={isMobile}>
        <ActiveCampaignForm formId={getFormId()} isMobile={isMobile} gradient />
      </FormCSSWrapper>
    </Wrapper>
  );
};
