import { LabelWithLinks } from '@homeproved/shared/feature-forms';
import { GetPathFunction } from '@homeproved/shared/feature-localized-routes';
import { Button } from '@homeproved/shared/ui';
import { Box, Typography } from '@material-ui/core';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

type SideBlockProps = {
  getPath: GetPathFunction;
};

const Wrapper = styled.div`
  width: 100%;
  background: ${({ theme }) => theme.config.gradients.rotated};
  padding: 2rem 2rem 4rem;
  border-radius: ${({ theme }) => theme.config.defaultBorderRadius};
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Title = styled(Typography)`
  margin-top: 2rem;
  font-size: 2rem;
  font-weight: 700;
  color: #fff;
  margin-bottom: 1.5rem;
`;
const Description = styled(Typography)`
  font-size: 1.4rem;
  line-height: 1.9rem;
  color: #fff;
  font-family: ${({ theme }) => theme.config.fonts.PTSans};
  margin-bottom: 4rem;
`;

export const SideBlock: FC<SideBlockProps> = ({ getPath }) => {
  const { t } = useTranslation();
  return (
    <Wrapper>
      <Title variant="h3">{t('app.com.pages.housingAdvice.detail.sideBlock.title')}</Title>
      <Description>
        <LabelWithLinks
          label={t('app.com.pages.housingAdvice.detail.sideBlock.description.text')}
          linksInLabel={[
            {
              label: t('app.com.pages.housingAdvice.detail.sideBlock.description.link1'),
              path: getPath('/housing-advice'),
            },
            {
              label: t('app.com.pages.housingAdvice.detail.sideBlock.description.link2'),
              path: getPath('/write-review'),
            },
          ]}
          options={{
            linkUnderline: 'always',
          }}
        />
      </Description>
      <Box display="flex" justifyContent="center">
        <Button variant="white" href={getPath('/write-review')}>
          {t('app.com.pages.housingAdvice.detail.sideBlock.cta')}
        </Button>
      </Box>
    </Wrapper>
  );
};
