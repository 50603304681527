import React, { FC } from 'react';
import styled from 'styled-components';

type WordpressWrapperProps = {
  isMobile: boolean;
};

const WordpressStyles = styled(({ mobile, ...restProps }) => <div {...restProps} />)`
  a {
    color: inherit;
  }
  p {
    &:first-child {
      font-family: ${({ theme }) => theme.config.fonts.Cabrito};
    }
    margin-top: 0;
    margin-bottom: 2rem;
    sub {
      position: relative;
      font-size: 75%;
      line-height: 0;
      vertical-align: baseline;
    }
  }
  ul,
  ol {
    padding-left: 1.8rem;
    li {
      font-size: 1.6rem;
      line-height: 2.4rem;
    }
  }
  figure,
  img {
    display: block;
    width: 100%;
    height: auto;
    margin-right: auto;
    margin-left: auto;
    border-radius: ${({ theme }) => theme.config.defaultBorderRadius};
  }
  video {
    width: 100%;
  }
  figcaption {
    font-size: 1.3rem;
    margin-top: 0.5rem;
    margin-left: 0.3rem;
  }
  blockquote.wp-block-quote {
    position: relative;
    padding: 15px 20px 35px;
    font-family: cabrito-normal, sans-serif;
    font-weight: 300;
    p {
      position: relative;
      display: block;
      margin: 15px 0 0;
      color: #3a3a3a;
      font-family: cabrito-normal, sans-serif;
      font-size: 18px;
      font-weight: 300;
      font-style: italic;
      line-height: 1.8;
      text-transform: none;
      &:before {
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: 15px;
        height: 13.5px;
        margin-right: 10px;
        background-repeat: no-repeat;
        background-position: 50%;
        background-size: cover;
        content: '';
        -ms-flex-negative: 0;
        flex-shrink: 0;
      }
    }
  }
  .wp-block-media-text {
    margin-bottom: 4rem;
    direction: ltr;
    display: -ms-grid;
    display: grid;
    grid-template-columns: ${({ mobile }) => (mobile ? '100%' : '50% 1fr')};
    grid-template-rows: auto;
    & > * {
      &:first-child {
        margin-bottom: ${({ mobile }) => mobile && '2rem'};
      }
    }
    figure {
      align-self: center;
      grid-column: 1;
      grid-row: 1;
      margin: 0;
      img {
        max-width: unset;
        vertical-align: middle;
      }
    }
    .wp-block-media-text__content {
      align-self: center;
      direction: ltr;
      grid-column: ${({ mobile }) => (mobile ? 1 : 2)};
      grid-row: ${({ mobile }) => (mobile ? 2 : 1)};
      padding: ${({ mobile }) => (mobile ? 0 : '0 8%')};
      word-break: break-word;
    }
    &.has-media-on-the-right {
      grid-template-columns: ${({ mobile }) => (mobile ? '100%' : '1fr 50%')};
      figure {
        grid-column: ${({ mobile }) => (mobile ? 1 : 2)};
      }
      .wp-block-media-text__content {
        grid-column: 1;
      }
    }
  }
  .wp-block-gallery {
    margin: 0;
    ul {
      display: flex;
      flex-wrap: wrap;
      list-style-type: none;
      padding: 0;
      margin: 0 -1rem;
      li {
        padding: 0 1rem;
        max-width: ${({ mobile }) => (mobile ? '100%' : '33%')};
        flex-basis: ${({ mobile }) => (mobile ? '100%' : '33%')};
        margin-bottom: 2rem;
        display: flex;
        flex-grow: 1;
        flex-direction: column;
        justify-content: center;
        position: relative;
        figure {
          display: flex;
          align-items: flex-end;
          justify-content: flex-start;
          width: 100%;
          height: 100%;
          margin: 0;
          img {
            height: 100%;
            flex: 1;
            object-fit: cover;
          }
          figcaption {
            position: absolute;
            bottom: 0;
            width: calc(100% - 2rem);
            max-height: 100%;
            overflow: auto;
            padding: 3rem 0.77rem 0.7rem;
            color: #fff;
            text-align: center;
            background: linear-gradient(
              0deg,
              rgba(0, 0, 0, 0.7),
              rgba(0, 0, 0, 0.3) 70%,
              transparent
            );
            box-sizing: border-box;
            margin: 0;
            flex-grow: 1;
            font-style: italic;
            line-height: 1.6rem;
            font-size: 1.2rem;
          }
        }
      }
    }
  }
`;

export const WordpressWrapper: FC<WordpressWrapperProps> = ({ isMobile, children }) => {
  return <WordpressStyles mobile={isMobile}>{children}</WordpressStyles>;
};
