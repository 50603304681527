import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { GetPathFunction } from '@homeproved/shared/feature-localized-routes';
import {
  Breadcrumb,
  Button,
  InfinitePagination,
  LargeTile,
  Pagination,
  SectionTitle,
  SectorSidebar,
  ShadowOverlay,
  theme,
} from '@homeproved/shared/ui';
import { useTranslation } from 'react-i18next';
import { Box, Typography, useMediaQuery } from '@material-ui/core';
import { useSectors, useTeasers } from '@homeproved/shared/feature-sectors';
import { useDisclosure } from 'react-use-disclosure';
import { createURL, useClickOutside, useNavVisible, usePageScroll } from '@homeproved/shared/util';
import {
  Wrapper,
  LoadingMessage,
  InnerWrapper,
  ContentWrapper,
  Sidebar,
  StyledA,
  Header,
  Title,
  SubTitle,
  Description,
  Tiles,
  HeaderContainer,
  NoPublications,
} from './components/Atoms';
import Link from 'next/link';
import { Article, Sector, SectorDescendant } from '@homeproved/shared/data-access';
import { FaqBlock } from './components/FaqBlock';
import { useArticlesBySector } from './api/useArticles';
import { articleListPagingConfig } from './api/config';
import { use100vh } from 'react-div-100vh';
import { Language, useCurrentLanguage, useSlugs } from '@homeproved/shared/feature-i18n';
import { SectorMenu } from './components/SectorMenu';
import { useRouter } from 'next/router';

type HousingAdviceSectorPageProps = {
  getPath: GetPathFunction;
  activeSector: string;
  articles?: Array<Article>;
};

export const HousingAdviceSectorPage: FC<HousingAdviceSectorPageProps> = ({
  getPath,
  activeSector,
  articles: initialArticles,
}) => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.xs));
  const isTablet = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.sm));
  const isSmallDesktop = useMediaQuery(theme.breakpoints.up(theme.breakpoints.values.sm));
  const isDesktop = useMediaQuery(theme.breakpoints.up(theme.breakpoints.values.md));
  const isLargerDesktop = useMediaQuery(theme.breakpoints.up(theme.breakpoints.values.md + 80));
  const { data: sectors, isLoading, isSuccess } = useSectors();
  const { data: teasers, isLoading: teasersLoading } = useTeasers();
  const {
    isOpen: offCanvasOpen,
    close: onCloseOffCanvas,
    toggle: onToggleOffCanvas,
  } = useDisclosure(false);
  const router = useRouter();
  const { date: dateParam, perPage: perPageParam, page: pageParam } = router.query;

  const sidebarRef: React.RefObject<HTMLDivElement> = useRef();
  const toggleBtnRef: React.RefObject<HTMLDivElement> = useRef();
  const secondToggleBtnRef: React.RefObject<HTMLDivElement> = useRef();
  useClickOutside(sidebarRef, onCloseOffCanvas, [toggleBtnRef, secondToggleBtnRef]);

  const articlesRef = React.useRef<HTMLInputElement>(null);
  const [filterQueryParams, setFilterQueryParams] = useState(null);
  const [refetchEnabled, setRefetchEnabled] = useState(false);

  const {
    data: articleData,
    isLoading: articlesLoading,
    refetch: articlesRefetch,
  } = useArticlesBySector(
    activeSector,
    filterQueryParams?.date,
    filterQueryParams?.perPage,
    filterQueryParams?.page,
    {
      options: {
        enabled: false,
      },
    }
  );

  const { setPageScrollEnabled } = usePageScroll();

  const pushParams = useCallback(
    (params) => {
      setPageScrollEnabled(false);
      router
        .replace(
          {
            pathname: getPath(router.pathname, {
              sector: activeSector,
            }),
            query: createURL(params),
          },
          undefined,
          { shallow: true }
        )
        .then(() => {
          setTimeout(() => {
            setPageScrollEnabled(true);
          }, 200);
        });
    },
    [router, activeSector, setPageScrollEnabled, getPath]
  );

  useEffect(() => {
    setFilterQueryParams({
      date: dateParam?.toString() ?? 'desc',
      perPage: parseInt(perPageParam?.toString() ?? '12'),
      page: parseInt(pageParam?.toString() ?? '1'),
    });

    if (dateParam == null && perPageParam == null && pageParam == null) {
      pushParams(articleListPagingConfig);
    } else {
      setRefetchEnabled(true);
    }
  }, [dateParam, perPageParam, pageParam, pushParams]);

  useEffect(() => {
    if (refetchEnabled) {
      setRefetchEnabled(false);
      articlesRefetch().then();
    }
  }, [refetchEnabled, articlesRefetch]);

  useEffect(() => {
    setPageScrollEnabled(!offCanvasOpen);
  }, [offCanvasOpen, setPageScrollEnabled]);

  const real100vh = use100vh();
  const navVisible = useNavVisible();

  const articles = articleData?.data || initialArticles;
  const pagination = articleData?.meta;

  const goToPage = (page: number) => {
    articlesRef.current.scrollIntoView({ behavior: 'smooth' });
    pushParams({ date: 'desc', perPage: 12, page });
  };

  const loadMorePages = () => {
    pushParams({ date: 'desc', page: 1, perPage: filterQueryParams.perPage + 12 });
  };

  const currentLanguage = useCurrentLanguage();

  const findSectorBySlug = useCallback((): Sector => {
    return (
      sectors?.find(({ data }) => data.slug.data[currentLanguage] === activeSector) ??
      sectors
        ?.find(({ data }) =>
          data.descendants?.some(
            (descendant: SectorDescendant) =>
              descendant.data.slug.data[currentLanguage] === activeSector
          )
        )
        ?.data.descendants?.find(
          (descendant: SectorDescendant) =>
            descendant.data.slug.data[currentLanguage] === activeSector
        ) ??
      teasers?.find(({ data }) => data.slug.data[currentLanguage] === activeSector)
    );
  }, [sectors, currentLanguage, activeSector, teasers]);

  const findParentSectorBySlug = useCallback((): Sector => {
    return (
      sectors?.find(({ data }) => data.slug.data[currentLanguage] === activeSector) ??
      sectors?.find(({ data }) =>
        data.descendants?.some(
          (descendant: SectorDescendant) =>
            descendant.data.slug.data[currentLanguage] === activeSector
        )
      ) ??
      teasers?.find(({ data }) => data.slug.data[currentLanguage] === activeSector)
    );
  }, [sectors, currentLanguage, activeSector, teasers]);

  const { setSlugs } = useSlugs();

  useEffect(() => {
    //Set slugs for language switcher
    setSlugs({
      sector: findSectorBySlug()?.data.slug.data as Record<Language, string>,
    });
  }, [findSectorBySlug, setSlugs]);

  if (!isLoading && !teasersLoading && findSectorBySlug() == null) {
    return (
      <Wrapper>
        <Box display="flex" alignItems="center" flexDirection="column">
          <Box mb={1}>
            <Typography variant="body1">
              {t('app.com.pages.housingAdvice.bySector.notFound')}
            </Typography>
          </Box>
          <Button href={getPath('/housing-advice')}>
            {t('app.com.pages.housingAdvice.bySector.notFoundCta')}
          </Button>
        </Box>
      </Wrapper>
    );
  }
  return (
    <Wrapper>
      <Box maxWidth="115.6rem" margin="auto">
        <Box position="relative">
          {!isMobile && (
            <Breadcrumb transparent>
              {`${t('app.com.pages.housingAdvice.bySector.breadCrumbs.inspire')} / `}
              <Link href={getPath('/housing-advice')} passHref>
                <StyledA href={getPath('/housing-advice')}>
                  {t('app.com.pages.housingAdvice.bySector.breadCrumbs.housingAdvice')}
                </StyledA>
              </Link>{' '}
              / <strong>{findSectorBySlug()?.data?.name}</strong>
            </Breadcrumb>
          )}
          <SectionTitle label={t('app.com.pages.housingAdvice.title')} fontSize={2.5} />
        </Box>
        <Box pt={isMobile ? 1 : 3}>
          {isLoading && <LoadingMessage>{t('app.com.pages.housingAdvice.loading')}</LoadingMessage>}
          {isSuccess && !isLoading && (
            <>
              <InnerWrapper mobile={isMobile}>
                <SectorMenu
                  sectors={sectors.map((sector) => ({
                    ...sector,
                    url: getPath('/housing-advice/sectors/:sector', {
                      sector: sector.data.slug.data[currentLanguage],
                    }),
                  }))}
                  isMobile={isMobile}
                  isLargerDesktop={isLargerDesktop}
                  offCanvasOpen={offCanvasOpen}
                  onToggleOffCanvas={onToggleOffCanvas}
                  toggleBtnRef={toggleBtnRef}
                  activeSector={activeSector}
                  secondToggleBtnRef={secondToggleBtnRef}
                />

                <ContentWrapper desktop={isDesktop}>
                  <div ref={articlesRef}>
                    <HeaderContainer>
                      <Header mobile={isMobile} tablet={isTablet}>
                        <Title variant="h1" mobile={isMobile}>
                          {findSectorBySlug()?.data?.name}
                        </Title>
                        <SubTitle variant="h2" mobile={isMobile}>{`${pagination?.total ?? ''} ${t(
                          'app.com.pages.housingAdvice.bySector.xProjects'
                        )}`}</SubTitle>
                        <Description variant="body1" mobile={isMobile}>
                          {findSectorBySlug()?.data?.description}
                        </Description>
                      </Header>
                      {isSmallDesktop && (
                        <FaqBlock isMobile={isMobile} isTablet={isTablet} getPath={getPath} />
                      )}
                    </HeaderContainer>
                    {articles?.length > 0 ? (
                      <Tiles isMobile={isMobile} isTablet={isTablet}>
                        {articles.map((article) => (
                          <Link
                            href={getPath('/housing-advice/articles/:article', {
                              article: article?.data?.slug,
                            })}
                            passHref
                            key={`article-${article?.data?.id}`}
                          >
                            <StyledA
                              href={getPath('/housing-advice/articles/:article', {
                                article: article?.data?.slug,
                              })}
                            >
                              <LargeTile
                                title={''}
                                description={article?.data?.title}
                                image={
                                  article?.data?.cover?.data?.conversions?.['small'] ||
                                  article?.data?.cover?.data?.original
                                }
                                isFavorite={article?.data?.isFavorite}
                                clickable
                              />
                            </StyledA>
                          </Link>
                        ))}
                        {isTablet && !isMobile && (
                          <FaqBlock isMobile={isMobile} isTablet={isTablet} getPath={getPath} />
                        )}
                      </Tiles>
                    ) : (
                      <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        mt={isMobile ? 0 : 3}
                        pb={isMobile ? 4 : 0}
                      >
                        {articlesLoading ? (
                          <LoadingMessage>
                            {t('app.com.pages.housingAdvice.loading')}
                          </LoadingMessage>
                        ) : (
                          <>
                            {/* No publications found */}
                            <NoPublications mobile={isMobile} textAlign="center">
                              {t('app.com.pages.housingAdvice.noArticles').replace(
                                '%SECTOR%',
                                findSectorBySlug()?.data?.name
                              )}
                            </NoPublications>
                            <Button
                              variant="gradient"
                              href={getPath('/housing-advice/sectors/:sector', {
                                sector: findParentSectorBySlug()?.data.slug.data[currentLanguage],
                              })}
                            >
                              {t('app.com.pages.housingAdvice.noArticlesCta')}
                            </Button>
                          </>
                        )}
                      </Box>
                    )}
                  </div>
                  {pagination != null &&
                    (isMobile ? (
                      <Box marginTop="-3rem" marginBottom="3rem">
                        <InfinitePagination {...pagination} loadMore={loadMorePages} />
                      </Box>
                    ) : (
                      <Pagination {...pagination} goToPage={goToPage} defaultPagesToShow={5} />
                    ))}
                  {isMobile && (
                    <FaqBlock isMobile={isMobile} isTablet={isTablet} getPath={getPath} />
                  )}
                </ContentWrapper>
              </InnerWrapper>
              <Sidebar
                innerRef={sidebarRef}
                offCanvas={true}
                offCanvasOpen={offCanvasOpen}
                real100vh={real100vh}
                navVisible={navVisible}
                tablet={isTablet}
              >
                <SectorSidebar
                  items={sectors.map((sector: Sector) => ({
                    id: sector.data.id,
                    name: sector.data.name,
                    icon: sector.data.icon,
                    isActive: sector.data.slug.data[currentLanguage] === activeSector,
                    url: getPath('/housing-advice/sectors/:sector', {
                      sector: sector.data.slug.data[currentLanguage],
                    }),
                    subItems: sector.data.descendants?.map((descendant: SectorDescendant) => ({
                      id: descendant.data.id,
                      name: descendant.data.name,
                      isActive: descendant.data.slug.data[currentLanguage] === activeSector,
                      url: getPath('/housing-advice/sectors/:sector', {
                        sector: descendant.data.slug.data[currentLanguage],
                      }),
                    })),
                  }))}
                  offCanvas={true}
                  onToggleOffCanvas={onToggleOffCanvas}
                />
              </Sidebar>
              {offCanvasOpen && isMobile && <ShadowOverlay />}
            </>
          )}
        </Box>
      </Box>
    </Wrapper>
  );
};
