import React, { FC } from 'react';
import { DancingScriptQuote } from '@homeproved/shared/ui';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Typography } from '@material-ui/core';

type NewsletterThanksPageProps = {
  confirmed: boolean;
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 3rem 0 6rem 0;
`;

const ThankYouMessage = styled.div`
  display: flex;
  margin-bottom: 3rem;
`;

const ThumbsUp = styled.div`
  margin-left: 1.5rem;
  width: 5rem;

  img {
    width: 100%;
  }
`;

const Title = styled(Typography)`
  font-family: ${({ theme }) => theme.config.fonts.Cabrito};
  font-size: 1.8rem;
  font-weight: 700;
  text-align: center;
  max-width: 45rem;
  line-height: 2.5rem;
`;

export const NewsletterThanksPage: FC<NewsletterThanksPageProps> = ({ confirmed }) => {
  const { t } = useTranslation();
  const translationBaseKey = confirmed
    ? 'app.com.pages.newsletter.thanks'
    : 'app.com.pages.newsletter.thanks.notConfirmed';

  return (
    <Wrapper>
      <ThankYouMessage>
        <DancingScriptQuote quote={t(`${translationBaseKey}.quote`)} color="dark" size={4.8} />
        <ThumbsUp>
          <img src="/approved2.png" alt="" loading="lazy" />
        </ThumbsUp>
      </ThankYouMessage>
      <Title variant="h2">{t(`${translationBaseKey}.title`)}</Title>
    </Wrapper>
  );
};
