import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { GetPathFunction } from '@homeproved/shared/feature-localized-routes';
import {
  Breadcrumb,
  Button,
  InfinitePagination,
  LargeTile,
  Pagination,
  SectionTitle,
  SectorSidebar,
  ShadowOverlay,
  theme,
} from '@homeproved/shared/ui';
import { useTranslation } from 'react-i18next';
import { Box, Typography, useMediaQuery } from '@material-ui/core';
import { useSectors } from '@homeproved/shared/feature-sectors';
import { useDisclosure } from 'react-use-disclosure';
import {
  createURL,
  objectToQueryString,
  useClickOutside,
  useNavVisible,
  usePageScroll,
} from '@homeproved/shared/util';
import {
  Wrapper,
  LoadingMessage,
  InnerWrapper,
  ContentWrapper,
  Sidebar,
  StyledA,
  Header,
  Title,
  SubTitle,
  Description,
  Tiles,
  HeaderContainer,
  NoPublications,
} from './components/Atoms';
import {
  PaginationMeta,
  Realisation,
  Sector,
  SectorDescendant,
  useQueryFetch,
} from '@homeproved/shared/data-access';
import Link from 'next/link';
import { use100vh } from 'react-div-100vh';
import { Language, useCurrentLanguage, useSlugs } from '@homeproved/shared/feature-i18n';
import { SectorMenu } from './components/SectorMenu';
import { useRouter } from 'next/router';
import { articleListPagingConfig } from './api/config';

type RealizationsSectorPageProps = {
  getPath: GetPathFunction;
  activeSector: string;
};

type RealizationData = {
  data: Realisation[];
  links: unknown;
  meta: unknown;
};

export const RealizationsSectorPage: FC<RealizationsSectorPageProps> = ({
  getPath,
  activeSector,
}) => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.xs));
  const isTablet = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.sm));
  const isDesktop = useMediaQuery(theme.breakpoints.up(theme.breakpoints.values.md));
  const isLargerDesktop = useMediaQuery(theme.breakpoints.up(theme.breakpoints.values.md + 80));
  const { data: sectors, isLoading, isSuccess } = useSectors();
  const {
    isOpen: offCanvasOpen,
    close: onCloseOffCanvas,
    toggle: onToggleOffCanvas,
  } = useDisclosure(false);
  const router = useRouter();
  const { date: dateParam, perPage: perPageParam, page: pageParam } = router.query;
  const sidebarRef: React.RefObject<HTMLDivElement> = useRef();
  const toggleBtnRef: React.RefObject<HTMLDivElement> = useRef();
  const secondToggleBtnRef: React.RefObject<HTMLDivElement> = useRef();
  useClickOutside(sidebarRef, onCloseOffCanvas, [toggleBtnRef, secondToggleBtnRef]);

  const realizationsRef = React.useRef<HTMLInputElement>(null);

  const apiRoute = `/api/sector/${activeSector}/realisations?`;

  const [filterQueryParams, setFilterQueryParams] = useState(null);
  const [refetchEnabled, setRefetchEnabled] = useState(false);

  const getApiRoute = () => {
    return apiRoute + objectToQueryString(filterQueryParams);
  };
  const {
    query: {
      data: realizationData,
      isSuccess: realizationsSuccess,
      isLoading: realizationsLoading,
      refetch: realizationsRefetch,
    },
  } = useQueryFetch<RealizationData, unknown>(['realizationsBySector'], getApiRoute(), {
    options: {
      enabled: false,
    },
  });

  const realizations = realizationData?.data || [];
  const pagination = realizationData?.meta as PaginationMeta;

  const goToPage = (page: number) => {
    realizationsRef.current.scrollIntoView({ behavior: 'smooth' });
    pushParams({ date: 'desc', perPage: 12, page });
  };

  const loadMorePages = () => {
    pushParams({ date: 'desc', page: 1, perPage: filterQueryParams.perPage + 12 });
  };

  const { setPageScrollEnabled } = usePageScroll();

  const pushParams = useCallback(
    (params) => {
      setPageScrollEnabled(false);
      router
        .replace(
          {
            pathname: getPath(router.pathname, {
              sector: activeSector,
            }),
            query: createURL(params),
          },
          undefined,
          { shallow: true }
        )
        .then(() => {
          setTimeout(() => {
            setPageScrollEnabled(true);
          }, 200);
        });
    },
    [router, activeSector, setPageScrollEnabled, getPath]
  );

  useEffect(() => {
    setFilterQueryParams({
      date: dateParam?.toString() ?? 'desc',
      perPage: parseInt(perPageParam?.toString() ?? '12'),
      page: parseInt(pageParam?.toString() ?? '1'),
    });

    if (dateParam == null && perPageParam == null && pageParam == null) {
      pushParams(articleListPagingConfig);
    } else {
      setRefetchEnabled(true);
    }
  }, [dateParam, perPageParam, pageParam, pushParams]);

  useEffect(() => {
    if (refetchEnabled) {
      setRefetchEnabled(false);
      realizationsRefetch().then();
    }
  }, [refetchEnabled, realizationsRefetch]);

  useEffect(() => {
    setPageScrollEnabled(!offCanvasOpen);
  }, [offCanvasOpen, setPageScrollEnabled]);

  const currentLanguage = useCurrentLanguage();
  const real100vh = use100vh();
  const navVisible = useNavVisible();

  const findSectorBySlug = useCallback((): Sector => {
    return (
      sectors?.find(({ data }) => data.slug.data[currentLanguage] === activeSector) ??
      sectors
        ?.find(({ data }) =>
          data.descendants?.some(
            (descendant: SectorDescendant) =>
              descendant.data.slug.data[currentLanguage] === activeSector
          )
        )
        ?.data.descendants?.find(
          (descendant: SectorDescendant) =>
            descendant.data.slug.data[currentLanguage] === activeSector
        )
    );
  }, [sectors, currentLanguage, activeSector]);

  const findParentSectorBySlug = useCallback((): Sector => {
    return (
      sectors?.find(({ data }) => data.slug.data[currentLanguage] === activeSector) ??
      sectors?.find(({ data }) =>
        data.descendants?.some(
          (descendant: SectorDescendant) =>
            descendant.data.slug.data[currentLanguage] === activeSector
        )
      )
    );
  }, [sectors, currentLanguage, activeSector]);

  const { setSlugs } = useSlugs();

  useEffect(() => {
    //Set slugs for language switcher
    setSlugs({
      sector: findSectorBySlug()?.data.slug.data as Record<Language, string>,
    });
  }, [findSectorBySlug, setSlugs]);

  if (!isLoading && findSectorBySlug() == null) {
    return (
      <Wrapper>
        <Box display="flex" alignItems="center" flexDirection="column">
          <Box mb={1}>
            <Typography variant="body1">
              {t('app.com.pages.realizations.bySector.notFound')}
            </Typography>
          </Box>
          <Button href={getPath('/realizations')}>
            {t('app.com.pages.realizations.bySector.notFoundCta')}
          </Button>
        </Box>
      </Wrapper>
    );
  }
  return (
    <Wrapper>
      <Box maxWidth="115.6rem" margin="auto">
        {!isMobile && (
          <Breadcrumb transparent>
            {`${t('app.com.pages.realizations.bySector.breadCrumbs.inspire')} / `}
            <Link href={getPath('/realizations')} passHref>
              <StyledA href={getPath('/realizations')}>
                {t('app.com.pages.realizations.bySector.breadCrumbs.realizations')}
              </StyledA>
            </Link>{' '}
            / <strong>{findSectorBySlug()?.data.name}</strong>
          </Breadcrumb>
        )}
        <SectionTitle label={t('app.com.pages.realizations.title')} fontSize={2.5} />
        <Box pt={isMobile ? 1 : 3}>
          {(isLoading || realizationsLoading) && (
            <LoadingMessage>{t('app.com.pages.realizations.loading')}</LoadingMessage>
          )}
          {isSuccess && realizationsSuccess && !isLoading && !realizationsLoading && (
            <>
              <InnerWrapper mobile={isMobile}>
                <SectorMenu
                  sectors={sectors.map((sector) => ({
                    ...sector,
                    url: getPath('/realizations/sectors/:sector', {
                      sector: sector.data.slug.data[currentLanguage],
                    }),
                  }))}
                  isMobile={isMobile}
                  isLargerDesktop={isLargerDesktop}
                  offCanvasOpen={offCanvasOpen}
                  onToggleOffCanvas={onToggleOffCanvas}
                  toggleBtnRef={toggleBtnRef}
                  secondToggleBtnRef={secondToggleBtnRef}
                  activeSector={activeSector}
                />

                <ContentWrapper desktop={isDesktop}>
                  <div ref={realizationsRef}>
                    {!isMobile && (
                      <HeaderContainer>
                        <Header mobile={isMobile} tablet={isTablet} noFaq>
                          <Title variant="h1" mobile={isMobile}>
                            {findSectorBySlug()?.data.name}
                          </Title>
                          <SubTitle variant="h2" mobile={isMobile}>{`${pagination?.total ?? ''} ${t(
                            'app.com.pages.housingAdvice.bySector.xProjects'
                          )}`}</SubTitle>
                          <Description variant="body1" mobile={isMobile}>
                            {findSectorBySlug()?.data.description}
                          </Description>
                        </Header>
                      </HeaderContainer>
                    )}
                    {realizations?.length > 0 ? (
                      <Tiles isMobile={isMobile} isTablet={isTablet}>
                        {realizations.map((realization) => (
                          <Link
                            href={getPath('/company/:slug/realization/:rslug', {
                              slug: realization.data.relations?.['company']?.data.slug,
                              rslug: realization.data.slug,
                            })}
                            key={`realization-${realization?.data?.id}`}
                          >
                            <StyledA
                              href={getPath('/company/:slug/realization/:rslug', {
                                slug: realization.data.relations?.['company']?.data.slug,
                                rslug: realization.data.slug,
                              })}
                            >
                              <LargeTile
                                title={realization.data.relations?.['company']?.data.name}
                                description={realization.data.title}
                                image={
                                  realization.data.cover?.data.conversions?.['small'] || realization.data.cover?.data.original
                                }
                                clickable
                              />
                            </StyledA>
                          </Link>
                        ))}
                      </Tiles>
                    ) : (
                      <Box display="flex" flexDirection="column" alignItems="center" mt={3}>
                        {realizationsLoading ? (
                          <LoadingMessage>{t('app.com.pages.realizations.loading')}</LoadingMessage>
                        ) : (
                          <>
                            {/* No publications found */}
                            <NoPublications mobile={isMobile} textAlign="center">
                              {t('app.com.pages.realizations.noRealizations').replace(
                                '%SECTOR%',
                                findSectorBySlug()?.data?.name
                              )}
                            </NoPublications>
                            <Button
                              variant="gradient"
                              href={getPath('/realizations/sectors/:sector', {
                                sector: findParentSectorBySlug()?.data.slug.data[currentLanguage],
                              })}
                            >
                              {t('app.com.pages.realizations.noRealizationsCta')}
                            </Button>
                          </>
                        )}
                      </Box>
                    )}
                  </div>
                  {pagination != null &&
                    (isMobile ? (
                      <InfinitePagination {...pagination} loadMore={loadMorePages} />
                    ) : (
                      <Pagination {...pagination} goToPage={goToPage} defaultPagesToShow={5} />
                    ))}
                </ContentWrapper>
              </InnerWrapper>
              <Sidebar
                innerRef={sidebarRef}
                offCanvas={true}
                offCanvasOpen={offCanvasOpen}
                real100vh={real100vh}
                navVisible={navVisible}
                tablet={isTablet}
              >
                <SectorSidebar
                  items={sectors.map((sector: Sector) => ({
                    id: sector.data.id,
                    name: sector.data.name,
                    icon: sector.data.icon,
                    isActive: sector.data.slug.data[currentLanguage] === activeSector,
                    url: getPath('/realizations/sectors/:sector', {
                      sector: sector.data.slug.data[currentLanguage],
                    }),
                    subItems: sector.data.descendants?.map((descendant: SectorDescendant) => ({
                      id: descendant.data.id,
                      name: descendant.data.name,
                      isActive: descendant.data.slug.data[currentLanguage] === activeSector,
                      url: getPath('/realizations/sectors/:sector', {
                        sector: descendant.data.slug.data[currentLanguage],
                      }),
                    })),
                  }))}
                  offCanvas={true}
                  onToggleOffCanvas={onToggleOffCanvas}
                />
              </Sidebar>
              {offCanvasOpen && isMobile && <ShadowOverlay />}
            </>
          )}
        </Box>
      </Box>
    </Wrapper>
  );
};
