import React, { FC } from 'react';
import { GetPathFunction } from '@homeproved/shared/feature-localized-routes';
import { Box, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import {
  StyledA,
  Wrapper,
  ArticleContent,
  ArticleTitle,
  ArticleSubtitle,
  ArticleDescription,
  CustomGrid,
  CustomGridItem,
  FlexibleContentText,
  StyledTextButton,
  GalleryTitle,
  Banner,
} from './components/Atoms';
import {
  Breadcrumb,
  Button,
  CompanyCard,
  Icons,
  ImageGallery,
  SectionTitle,
} from '@homeproved/shared/ui';
import Link from 'next/link';
import ReactHtmlParser from 'react-html-parser';
import { FourArticles } from './components/FourArticles';
import { NewsletterSubscriptionTile } from '@homeproved/shared/feature-newsletter';
import { SideBlock } from './components/SideBlock';
import { WordpressWrapper } from './components/WordpressWrapper';
import { ArticleImage } from './components/ArticleImage';
import { ArticleData } from '@homeproved/shared/data-access';

type HousingAdviceDetailPageProps = {
  getPath: GetPathFunction;
  article?: ArticleData;
};

export const HousingAdviceDetailPage: FC<HousingAdviceDetailPageProps> = ({ getPath, article }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.xs));
  const isTablet = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.sm));
  const isDesktop = useMediaQuery(theme.breakpoints.up(theme.breakpoints.values.md));

  if (!article) {
    return (
      <Wrapper>
        <Box display="flex" alignItems="center" flexDirection="column" mt={7}>
          <Box mb={1}>
            <Typography variant="body1">
              {t('app.com.pages.housingAdvice.detail.notFound')}
            </Typography>
          </Box>
          <Button href={getPath('/housing-advice')}>
            {t('app.com.pages.housingAdvice.detail.notFoundCta')}
          </Button>
        </Box>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      {!isMobile && (
        <Breadcrumb absolute>
          {`${t('app.com.pages.housingAdvice.detail.breadCrumbs.inspire')} / `}
          <Link href={getPath('/housing-advice')} passHref>
            <StyledA href={getPath('/housing-advice')}>
              {t('app.com.pages.housingAdvice.detail.breadCrumbs.housingAdvice')}
            </StyledA>
          </Link>
          {article?.relations?.company != null && (
            <>
              {' / '}
              <Link
                href={getPath('/company/:slug/reviews', {
                  slug: article.relations.company.data.slug,
                })}
                passHref
              >
                <StyledA
                  href={getPath('/company/:slug/reviews', {
                    slug: article.relations.company.data.slug,
                  })}
                >
                  {article.relations.company.data.name}
                </StyledA>
              </Link>
            </>
          )}
        </Breadcrumb>
      )}
      {isMobile && (
        <Box mt={3}>
          <StyledTextButton
            variant="text"
            href={getPath('/housing-advice')}
            icon={Icons.ANGLE_LEFT}
          >
            {t('app.com.pages.housingAdvice.detail.back')}
          </StyledTextButton>
        </Box>
      )}
      <ArticleContent mobile={isMobile}>
        {article?.relations?.company?.data?.name && (
          <Box
            mb={isMobile ? 3 : 1}
            display="flex"
            flexDirection="column"
            alignItems={isMobile ? 'center' : 'flex-start'}
          >
            <Button
              variant={isMobile ? 'dark' : 'gradient'}
              icon={Icons.HELMET}
              pill={false}
              arrow="none"
              href={getPath('/company/:slug/reviews', {
                slug: article.relations.company.data.slug,
              })}
            >
              {article.relations?.company?.data?.name}
            </Button>
          </Box>
        )}
        <ArticleTitle variant="h1" mobile={isMobile} subtitleIsDefined={article?.subtitle != null}>
          {article.title}
        </ArticleTitle>
        {article?.subtitle != null && (
          <ArticleSubtitle variant="h2" mobile={isMobile}>
            {article?.subtitle}
          </ArticleSubtitle>
        )}
        <Box display="flex">
          <Box maxWidth={isDesktop ? '70%' : '100%'} flexBasis={isDesktop ? '70%' : '100%'}>
            <Banner src={article.banner?.data?.original} alt={article.title} />
            {article.description != null && (
              <ArticleDescription variant="body1" mobile={isMobile}>
                {article.description}
              </ArticleDescription>
            )}
            {article.flexibleContent?.map((articleContent, index) => (
              <CustomGrid
                key={`flexible-content-${index}`}
                mobile={isMobile}
                noMarginBottom={
                  !isTablet &&
                  (articleContent['name'] === 'photo_text' ||
                    articleContent['name'] === 'text_photo') &&
                  (article.flexibleContent[index + 1]?.['name'] === 'photo_text' ||
                    article.flexibleContent[index + 1]?.['name'] === 'text_photo')
                }
              >
                {articleContent['name'] === 'photo_text' ? (
                  <>
                    <CustomGridItem variant="left" mobile={isMobile} tablet={isTablet} order={1}>
                      <ArticleImage
                        src={articleContent['fields'].image}
                        alt={articleContent['fields'].caption ?? ''}
                        title={articleContent['fields'].caption ?? ''}
                        loading="lazy"
                        maxWidth={isMobile ? '100%' : isTablet ? '75rem' : '100%'}
                        caption={articleContent['fields'].caption ?? ''}
                        isTablet={isTablet}
                        captionAbsolute={
                          (articleContent['name'] === 'photo_text' ||
                            articleContent['name'] === 'text_photo') &&
                          (article.flexibleContent[index + 1]?.['name'] === 'photo_text' ||
                            article.flexibleContent[index + 1]?.['name'] === 'text_photo')
                        }
                      />
                    </CustomGridItem>
                    <CustomGridItem variant="right" mobile={isMobile} tablet={isTablet} order={2}>
                      <FlexibleContentText
                        tablet={isTablet}
                        mobile={isMobile}
                        maxWidth={isMobile ? '100%' : isTablet ? '75rem' : '100%'}
                      >
                        {ReactHtmlParser(articleContent['fields'].text)}
                      </FlexibleContentText>
                    </CustomGridItem>
                  </>
                ) : articleContent['name'] === 'text_photo' ? (
                  <>
                    <CustomGridItem
                      variant="left"
                      mobile={isMobile}
                      tablet={isTablet}
                      order={isTablet ? 2 : 1}
                    >
                      <FlexibleContentText
                        tablet={isTablet}
                        mobile={isMobile}
                        maxWidth={isMobile ? '100%' : isTablet ? '75rem' : '100%'}
                      >
                        {ReactHtmlParser(articleContent['fields'].text)}
                      </FlexibleContentText>
                    </CustomGridItem>
                    <CustomGridItem
                      variant="right"
                      mobile={isMobile}
                      tablet={isTablet}
                      order={isTablet ? 1 : 2}
                    >
                      <ArticleImage
                        src={articleContent['fields'].image}
                        alt={articleContent['fields'].caption ?? ''}
                        title={articleContent['fields'].caption ?? ''}
                        loading="lazy"
                        maxWidth={isMobile ? '100%' : isTablet ? '75rem' : '100%'}
                        caption={articleContent['fields'].caption ?? ''}
                        isTablet={isTablet}
                        captionAbsolute={
                          (articleContent['name'] === 'photo_text' ||
                            articleContent['name'] === 'text_photo') &&
                          (article.flexibleContent[index + 1]?.['name'] === 'photo_text' ||
                            article.flexibleContent[index + 1]?.['name'] === 'text_photo')
                        }
                      />
                    </CustomGridItem>
                  </>
                ) : articleContent['name'] === 'wysiwyg' ||
                  articleContent['name'] === 'wordpress' ? (
                  <CustomGridItem variant="spread" mobile={isMobile} tablet={isTablet} order={1}>
                    <FlexibleContentText
                      tablet={isTablet}
                      mobile={isMobile}
                      maxWidth={isMobile ? '100%' : isTablet ? '75rem' : '100%'}
                      noPadding
                    >
                      {articleContent['fields'].title && (
                        <h2>{ReactHtmlParser(articleContent['fields'].title)}</h2>
                      )}
                      {articleContent['fields'].content &&
                        (articleContent['name'] === 'wordpress' ? (
                          <WordpressWrapper isMobile={isMobile}>
                            {ReactHtmlParser(articleContent['fields'].content)}
                          </WordpressWrapper>
                        ) : (
                          ReactHtmlParser(articleContent['fields'].content)
                        ))}
                    </FlexibleContentText>
                  </CustomGridItem>
                ) : articleContent['name'] === 'double_photo' ? (
                  <>
                    <CustomGridItem
                      variant="right"
                      mobile={isMobile}
                      tablet={isTablet}
                      order={1}
                      marginBottom={
                        (isTablet ||
                          articleContent['fields'].caption_left?.length > 0 ||
                          articleContent['fields'].caption_right?.length > 0) &&
                        '2rem'
                      }
                    >
                      <ArticleImage
                        src={articleContent['fields'].left_image}
                        alt={articleContent['fields'].alt_left ?? ''}
                        title={articleContent['fields'].alt_left ?? ''}
                        loading="lazy"
                        maxWidth={isMobile ? '100%' : isTablet ? '75rem' : '100%'}
                        covered
                        caption={articleContent['fields'].caption_left ?? ''}
                        isTablet={isTablet}
                        captionAbsolute
                      />
                    </CustomGridItem>
                    <CustomGridItem
                      variant="left"
                      mobile={isMobile}
                      tablet={isTablet}
                      order={2}
                      marginBottom={
                        !isTablet &&
                        (articleContent['fields'].caption_left?.length > 0 ||
                          articleContent['fields'].caption_right?.length > 0) &&
                        '2rem'
                      }
                    >
                      <ArticleImage
                        src={articleContent['fields'].right_image}
                        alt={articleContent['fields'].alt_right ?? ''}
                        title={articleContent['fields'].alt_right ?? ''}
                        loading="lazy"
                        maxWidth={isMobile ? '100%' : isTablet ? '75rem' : '100%'}
                        covered
                        caption={articleContent['fields'].caption_right ?? ''}
                        isTablet={isTablet}
                        captionAbsolute
                      />
                    </CustomGridItem>
                  </>
                ) : articleContent['name'] === 'single_photo' ? (
                  <CustomGridItem variant="center" mobile={isMobile} tablet={isTablet}>
                    <ArticleImage
                      src={articleContent['fields'].image}
                      alt={articleContent['fields'].alt ?? ''}
                      title={articleContent['fields'].alt ?? ''}
                      loading="lazy"
                      maxWidth={isMobile ? '100%' : isTablet ? '75rem' : '100%'}
                      caption={articleContent['fields'].caption ?? ''}
                      isTablet={isTablet}
                    />
                  </CustomGridItem>
                ) : articleContent['name'] === 'gallery' ? (
                  <Box
                    marginLeft={isMobile ? '-0.5rem' : '2.5rem'}
                    marginRight={isMobile ? '-0.5rem' : '2.5rem'}
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    width={isMobile ? 'auto' : '100%'}
                  >
                    <GalleryTitle variant="h2">
                      {t('app.com.pages.housingAdvice.detail.photoGallery')}
                    </GalleryTitle>
                    <Box maxWidth={isMobile ? '100%' : isTablet ? '75rem' : '100%'}>
                      <ImageGallery
                        images={[articleContent['fields'][0], ...articleContent['fields'].slice(1)]}
                        bigger={true}
                        isMobile={isMobile}
                        company={{
                          name: article?.relations?.company?.data?.name,
                          url: getPath('/company/:slug/reviews', {
                            slug: article?.relations?.company?.data?.slug,
                          }),
                        }}
                      />
                    </Box>
                  </Box>
                ) : articleContent['name'] === 'html' ? (
                  <CustomGridItem variant="center" mobile={isMobile} tablet={isTablet}>
                    {ReactHtmlParser(articleContent['fields'].html)}
                  </CustomGridItem>
                ) : null}
              </CustomGrid>
            ))}
          </Box>
          {isDesktop && (
            <Box maxWidth="30%" flexBasis="30%" pl={4}>
              <Box mb={3}>
                <NewsletterSubscriptionTile />
              </Box>
              <SideBlock getPath={getPath} />
            </Box>
          )}
        </Box>
        {!isMobile && article?.relations?.company?.data != null && (
          <>
            <Box mb={5} mt={7}>
              <SectionTitle label={t('app.com.pages.housingAdvice.detail.titleRealizationOf')} />
            </Box>
            <Box mb={7}>
              <CompanyCard
                company={article.relations.company.data}
                companyPath={getPath('/company/:slug', {
                  slug: article.relations.company.data.slug,
                })}
              />
            </Box>
          </>
        )}
        {article.relations.otherPublications?.length > 0 ? (
          <>
            <Box mb={5} mt={isMobile ? 4 : 0}>
              <SectionTitle
                label={`${t('app.com.pages.housingAdvice.detail.titleMoreArticlesOf')} ${
                  article.relations?.['primarySector']?.data?.name
                }`}
              />
            </Box>
            <Box mb={7}>
              <FourArticles
                articles={article.relations.otherPublications?.slice(0, 4).map((publication) => {
                  const link = getPath('/housing-advice/articles/:article', {
                    article: publication?.['data']?.slug,
                  });
                  return {
                    id: publication?.['data']?.id,
                    name: publication?.['data']?.relations?.primarySector?.data?.name,
                    title: publication?.['data']?.title,
                    image: publication?.['data']?.cover?.data,
                    url: link,
                  };
                })}
                isMobile={isMobile}
                isTablet={isTablet}
              />
            </Box>
          </>
        ) : (
          <Box mt={4} mb={7}></Box>
        )}
      </ArticleContent>
    </Wrapper>
  );
};
