import { FC } from 'react';
import {
  Article,
  ArticlesApiFactory,
  useApiFactory,
  useQueryFetch,
} from '@homeproved/shared/data-access';

type MostReadArticlesHandlerProps = {
  onSuccess: (data: Article[]) => void;
  currentLanguage: string;
};

export const MostReadArticlesHandler: FC<MostReadArticlesHandlerProps> = ({
  onSuccess,
  currentLanguage,
}) => {
  const articlesApi = useApiFactory(ArticlesApiFactory);

  useQueryFetch(
    ['mostReadArticles', currentLanguage],
    () => articlesApi.apiArticleMostReadGet('desc', 20),
    {
      options: {
        onSuccess,
      },
    }
  );

  return null;
};
