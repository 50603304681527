import {
  RealisationApiFactory,
  useApiFactory,
  useQueryFetch,
} from '@homeproved/shared/data-access';
import { useCurrentLanguage } from '@homeproved/shared/feature-i18n';

export const useRealizations = (company) => {
  const currentLanguage = useCurrentLanguage();

  const realizationsApi = useApiFactory(RealisationApiFactory);
  const { query } = useQueryFetch(
    ['moreRealizationsOf', currentLanguage],
    () => realizationsApi.apiCompaniesCompanyRealisationsGet(company),
    {
      options: {
        enabled: !!company,
      },
    }
  );
  return {
    data: query.data,
    isSuccess: query.isSuccess,
    isError: query.isError,
    isLoading: query.isLoading,
  };
};

export const useLatestRealizations = () => {
  const currentLanguage = useCurrentLanguage();

  const realizationsApi = useApiFactory(RealisationApiFactory);
  const { query } = useQueryFetch(['latestRealizations', currentLanguage], () =>
    realizationsApi.apiRealisationGet('desc', 4)
  );
  return {
    data: query.data,
    isSuccess: query.isSuccess,
    isError: query.isError,
    isLoading: query.isLoading,
  };
};

export const useMostReadRealizations = () => {
  const currentLanguage = useCurrentLanguage();

  const realizationsApi = useApiFactory(RealisationApiFactory);
  const { query } = useQueryFetch(['mostReadRealizations', currentLanguage], () =>
    realizationsApi.apiRealisationMostReadGet('desc', 4)
  );
  return {
    data: query.data,
    isSuccess: query.isSuccess,
    isError: query.isError,
    isLoading: query.isLoading,
  };
};
