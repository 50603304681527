import React, { FC, useEffect, useRef } from 'react';
import { GetPathFunction } from '@homeproved/shared/feature-localized-routes';
import { SectionTitle, SectorSidebar, ShadowOverlay, theme } from '@homeproved/shared/ui';
import { useTranslation } from 'react-i18next';
import { Box, useMediaQuery } from '@material-ui/core';
import { useSectors } from '@homeproved/shared/feature-sectors';
import { useDisclosure } from 'react-use-disclosure';
import { useClickOutside, useNavVisible, usePageScroll } from '@homeproved/shared/util';
import {
  Wrapper,
  LoadingMessage,
  InnerWrapper,
  ContentWrapper,
  Sidebar,
  StyledA,
} from './components/Atoms';
import { useArticles } from './api/useArticles';
import { Cover } from './components/Cover';
import { FourArticles } from './components/FourArticles';
import { NewsletterSection } from './components/NewsletterSection';
import TrackVisibility from 'react-on-screen';
import { MostRead } from './components/MostRead';
import { TeaserBlockOne } from './components/TeaserBlockOne';
import { TeaserBlockTwo } from './components/TeaserBlockTwo';
import Link from 'next/link';
import { useArticleTeasers } from './api/useArticleTeasers';
import { use100vh } from 'react-div-100vh';
import { useCurrentLanguage } from '@homeproved/shared/feature-i18n';
import { Article, Sector, SectorDescendant } from '@homeproved/shared/data-access';
import { SectorMenu } from './components/SectorMenu';

type HousingAdvicePageProps = {
  getPath: GetPathFunction;
  articles: Array<Article>;
  teasers: Array<Sector>;
};

export const HousingAdvicePage: FC<HousingAdvicePageProps> = ({
  getPath,
  articles: articlesData,
  teasers: teasersData,
}) => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.xs));
  const isTablet = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.sm));
  const isDesktop = useMediaQuery(theme.breakpoints.up(theme.breakpoints.values.md));
  const isLargerDesktop = useMediaQuery(theme.breakpoints.up(theme.breakpoints.values.md + 80));
  const { data: sectors, isLoading } = useSectors();
  const {
    isOpen: offCanvasOpen,
    close: onCloseOffCanvas,
    toggle: onToggleOffCanvas,
  } = useDisclosure(false);
  const sidebarRef: React.RefObject<HTMLDivElement> = useRef();
  const toggleBtnRef: React.RefObject<HTMLDivElement> = useRef();
  const secondToggleBtnRef: React.RefObject<HTMLDivElement> = useRef();
  useClickOutside(sidebarRef, onCloseOffCanvas, [toggleBtnRef, secondToggleBtnRef]);

  const { data: articleData, isLoading: articlesLoading } = useArticles();
  const articles = articleData?.data || articlesData;

  const { data: articleTeaserData, isLoading: articleTeaserLoading } = useArticleTeasers();
  const articleTeasers = articleTeaserData?.data || teasersData;
  const { setPageScrollEnabled } = usePageScroll();

  useEffect(() => {
    setPageScrollEnabled(!offCanvasOpen);
  }, [offCanvasOpen, setPageScrollEnabled]);

  const currentLanguage = useCurrentLanguage();

  const real100vh = use100vh();
  const navVisible = useNavVisible();

  return (
    <Wrapper>
      <Box margin="auto">
        <SectionTitle label={t('app.com.pages.housingAdvice.title')} fontSize={2.5} />
        <Box pt={isMobile ? 1 : 3}>
          {(isLoading || articlesLoading) && (
            <LoadingMessage>{t('app.com.pages.housingAdvice.loading')}</LoadingMessage>
          )}
          <>
            <InnerWrapper mobile={isMobile}>
              <SectorMenu
                sectors={sectors.map((sector) => ({
                  ...sector,
                  url: getPath('/housing-advice/sectors/:sector', {
                    sector: sector.data.slug.data[currentLanguage],
                  }),
                }))}
                isMobile={isMobile}
                isLargerDesktop={isLargerDesktop}
                offCanvasOpen={offCanvasOpen}
                onToggleOffCanvas={onToggleOffCanvas}
                toggleBtnRef={toggleBtnRef}
                secondToggleBtnRef={secondToggleBtnRef}
              />
              {articles?.length > 0 && (
                <ContentWrapper desktop={isDesktop}>
                  {!isMobile && (
                    <TrackVisibility>
                      {({ isVisible }) => (
                        <Link
                          href={getPath('/housing-advice/articles/:article', {
                            article: articles[0]?.data.slug,
                          })}
                          passHref
                        >
                          <StyledA
                            href={getPath('/housing-advice/articles/:article', {
                              article: articles[0]?.data.slug,
                            })}
                          >
                            <Cover
                              article={{
                                image: articles[0]?.data.banner?.data,
                                name: articles[0]?.data.relations?.['primarySector']?.data.name,
                                title: articles[0]?.data.title,
                                description: articles[0]?.data.description,
                                isFavorite: articles[0]?.data.isFavorite,
                              }}
                              isVisible={isVisible}
                              isMobile={isMobile}
                              isTablet={isTablet}
                              height="38rem"
                              showDescription={true}
                              bannerMaxWidth="85rem"
                            />
                          </StyledA>
                        </Link>
                      )}
                    </TrackVisibility>
                  )}
                  <FourArticles
                    articles={articles
                      .filter((_, index) => (isMobile ? index < 2 : index > 1))
                      .slice(0, 4)
                      .map((article) => ({
                        id: article?.data.id,
                        name: '',
                        title: article?.data.title,
                        image: article?.data.cover?.data,
                        url: getPath('/housing-advice/articles/:article', {
                          article: article?.data.slug,
                        }),
                        isFavorite: article?.data.isFavorite,
                      }))}
                    isMobile={isMobile}
                    isTablet={isTablet}
                  />
                  {articles.length > 1 && (
                    <TrackVisibility>
                      {({ isVisible }) => (
                        <NewsletterSection
                          article={{
                            image: articles[1]?.data.banner?.data,
                            name: '',
                            title: articles[1]?.data.title,
                            description: articles[1]?.data.description,
                            isFavorite: articles[1]?.data.isFavorite,
                            url: getPath('/housing-advice/articles/:article', {
                              article: articles[1]?.data.slug,
                            }),
                          }}
                          isMobile={isMobile}
                          isTablet={isTablet}
                          isDesktop={isDesktop}
                          isVisible={isVisible}
                        />
                      )}
                    </TrackVisibility>
                  )}
                </ContentWrapper>
              )}
            </InnerWrapper>
            <Sidebar
              innerRef={sidebarRef}
              offCanvas={true}
              offCanvasOpen={offCanvasOpen}
              real100vh={real100vh}
              navVisible={navVisible}
              tablet={isTablet}
            >
              <SectorSidebar
                items={sectors.map((sector: Sector) => ({
                  id: sector.data.id,
                  name: sector.data.name,
                  icon: sector.data.icon,
                  url: getPath('/housing-advice/sectors/:sector', {
                    sector: sector.data.slug.data[currentLanguage],
                  }),
                  subItems: sector.data.descendants?.map((descendant: SectorDescendant) => ({
                    id: descendant.data.id,
                    name: descendant.data.name,
                    url: getPath('/housing-advice/sectors/:sector', {
                      sector: descendant.data.slug.data[currentLanguage],
                    }),
                  })),
                }))}
                offCanvas={true}
                onToggleOffCanvas={onToggleOffCanvas}
              />
            </Sidebar>
            {offCanvasOpen && isMobile && <ShadowOverlay />}
          </>
        </Box>
      </Box>
      <MostRead isMobile={isMobile} isTablet={isTablet} type="articles" getPath={getPath} />
      {articleTeaserLoading && (
        <LoadingMessage>{t('app.com.pages.housingAdvice.loading')}</LoadingMessage>
      )}
      <>
        {articleTeasers?.[0] != null && (
          <TeaserBlockOne
            isMobile={isMobile}
            isTablet={isTablet}
            teaser={articleTeasers?.[0].data}
            getPath={getPath}
            currentLanguage={currentLanguage}
          />
        )}
        {articleTeasers
          ?.filter((_, index) => index !== 0)
          .map(({ data }) => (
            <TeaserBlockTwo
              teaser={data}
              key={`teaser-block-${data.id}`}
              isMobile={isMobile}
              isTablet={isTablet}
              getPath={getPath}
              currentLanguage={currentLanguage}
            />
          ))}
      </>
    </Wrapper>
  );
};
