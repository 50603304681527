import { Typography } from '@material-ui/core';
import React, { FC } from 'react';
import styled from 'styled-components';
import { CoveredImage, StyledImage } from './Atoms';

type ArticleImageProps = {
  src: string;
  alt: string;
  title: string;
  loading: string;
  maxWidth: string;
  caption: string;
  covered?: boolean;
  isTablet?: boolean;
  captionAbsolute?: boolean;
};

const ArticleImageWrapper = styled(({ tablet, stretch, ...restProps }) => <div {...restProps} />)`
  position: relative;
  margin: 0 auto;
  max-width: 100%;
  ${({ stretch }) =>
    stretch &&
    `
      display: flex;
    flex-direction: column;
    flex-grow: 1;
  `}
`;

const Caption = styled(({ maxWidth, tablet, absolute, ...restProps }) => (
  <Typography {...restProps} />
))`
  font-family: ${({ theme }) => theme.config.fonts.PTSans};
  font-size: 1.3rem;
  max-width: ${({ maxWidth }) => maxWidth};
  width: 100%;
  margin: auto;
  position: ${({ tablet, absolute }) => (tablet || !absolute ? 'relative' : 'absolute')};
  bottom: 0;
  left: 0.3rem;
  transform: ${({ tablet, absolute }) => !tablet && absolute && 'translateY(100%)'};
  display: block;
`;

export const ArticleImage: FC<ArticleImageProps> = ({
  src,
  alt,
  title,
  loading,
  maxWidth,
  caption,
  covered = false,
  isTablet,
  captionAbsolute,
}) => {
  return covered ? (
    <ArticleImageWrapper tablet={isTablet} stretch>
      <CoveredImage src={src} alt={alt} title={title} loading={loading} maxWidth={maxWidth} />
      <Caption variant="caption" maxWidth={maxWidth} tablet={isTablet} absolute={captionAbsolute}>
        {caption}
      </Caption>
    </ArticleImageWrapper>
  ) : (
    <ArticleImageWrapper tablet={isTablet}>
      <StyledImage src={src} alt={alt} title={title} loading={loading} maxWidth={maxWidth} />
      <Caption variant="caption" maxWidth={maxWidth} tablet={isTablet} absolute={captionAbsolute}>
        {caption}
      </Caption>
    </ArticleImageWrapper>
  );
};
